.main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px 30px 25px 30px;
  border-radius: 20px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  background: rgba(228, 228, 255, 0.4);
  box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
  margin-top: 20px;
}