.main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px 35px 15px 20px;
  .header{
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 35.52px;
    text-align: left;
    color: #140D42;
  }
  .goals{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    gap: 25px;
  }
  .analytics{
    display: flex;
    flex-direction: row;
    gap: 80px;
    padding: 30px 30px 30px 25px;
    background: rgba(228, 228, 255, 0.4);
    border-radius: 25px;
    border: 1px solid;
    border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    box-shadow: 3px 8px 16.6px 2px #5B50A92E;
    .content{
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 21.86px;
        text-align: left;
      }
      .text{
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
      }
      .button{
        color: #E5E1FC;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        background: #5B50A9;
        padding: 8px 14px 8px 14px;
        border-radius: 8px;
        border: 1px solid #5B50A9;
        box-shadow: 0 1px 2px 0 #1018280D;
        width: fit-content;
        margin-left: auto;
        margin-top: 10px;
      }
    }
  }
  .profile_cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:12px;
  }
}