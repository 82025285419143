.main_wrapper{
  display: grid;
  gap: 25px;
  grid-template-columns: 86px 336px auto;
  .icons{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background: rgba(228, 228, 255, 0.69);
    box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
    border: 1px solid;
    border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    height: fit-content;
    border-radius: 20px;
    svg{
      width: 20px;
      height: 20px;
    }
    .add_file{
      padding: 12px;
      border: 1px solid rgba(196, 232, 167, 1);
      background: rgba(196, 232, 167, 1);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      display: flex;
      svg{
        path {
          stroke: rgba(72, 123, 31, 1);
        }
      }
    }
    .four_stripes{
      padding: 12px;
      border: 1px solid rgba(192, 202, 255, 1);
      background: rgba(192, 202, 255, 1);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      display: flex;
      svg{
        path {
          stroke: rgba(68, 56, 150, 1);
        }
      }}
    .calendar {
      padding: 12px;
      border: 1px solid rgba(186, 20, 116, 1);
      background: rgba(186, 20, 116, 1);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      display: flex;
    }
    .plus {
      padding: 12px;
      border: 2px solid rgba(91, 80, 169, 1);
      background: none;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      display: flex;

      svg {
        path {
          stroke: rgba(91, 80, 169, 1);
        }
      }
    }
  }
  .chat_list{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 11px;
    background: rgba(228, 228, 255, 0.69);
    box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
    border: 1px solid;
    border-radius: 20px;
    border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    flex-shrink: 0;
  }
  .chat_field{
    background: rgba(91, 80, 169, 0.26);
    box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
    border: 1px solid;
    border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    border-radius: 20px;
    max-width: 65vw;
    display: flex;
    height: 91.5vh;
    &--closed {
      align-items: center;
      justify-content: center;
    }
    .chat_field_card{
      color: rgba(229, 225, 252, 1);
      background: rgba(91, 80, 169, 1);
      box-shadow: 3px 8px 16.6px 2px rgba(91, 80, 169, 0.18);
      padding: 30px 25px 30px 25px;
      border-radius: 20px;
      max-width: 40%;
      text-align: center;
    }
  }
}