.main_wrapper{
  padding: 20px 15px 20px 15px;
  border-radius: 20px;
  background: rgba(228, 228, 255, 0.4);
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 3px 8px 16.6px 2px rgba(91, 80, 169, 0.18);
  display: flex;
  flex-direction: row;
  gap: 12px;
  .avatar_wrapper{
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    svg{
      width: 48px;
      height: 48px;
      flex-shrink: 0;
    }
  }
  .owner_info{
    display: flex;
    flex-direction: column;

    .name{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin: 0;
    }
    .info{
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: rgba(22, 16, 64, 0.3);
      margin: 0;
    }
  }
}