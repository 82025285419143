.title{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  margin-bottom: 10px;
  margin-right: 25px;
}
.description{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.second-blob{
  grid-column: 2;
  grid-row: 1;
}

.text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon{
  background: #5B50A9;
  border-radius: 100%;
  width: 37px;
  height: 37px;

  align-self: end;

  display: flex;
  justify-content: center;
  place-items: center;
}

.main-wrapper{
  display: flex;
}

.button-wrapper{
  display: flex;
}