.card_main_wrapper{
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 25px 30px;
  border-radius: 40px;
  background: #E4E4FF66;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 8px 12px 20px 2px #5B50A940;
  .card_picture_wrapper{
    flex-shrink: 0;
    align-items: center;
  }
  .card_text_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .card_text_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .card_text_title_text{
        display: flex;
        flex-direction: column;
        .card_text_title_title{
          font-size: 16px;
          font-weight: 600;
          line-height: 21.86px;
          margin:0;
        }
        .card_text_title_time{
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          margin: 0;
        }
      }
      .card_text_title_icons {
        display: flex;
        flex-direction: row;
        gap: 5px;
        .tag{
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          padding: 2px 10px;
          border-radius: 16px;
          color: #443896;
          background: #E1DEFF;
        }
      }
    }
    .card_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      margin: 0;
    }
  }
}