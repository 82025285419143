.emplyee-aim {
  width: 50px;
  height: 4px;
  background-color: rgba(199, 191, 255, 1);
  border-radius: 20px;
}

.emplyee-aim-active {
  width: 50px;
  height: 4px;
  border-radius: 20px;
  background-color: rgba(91, 80, 169, 1);
}
