.card-wrapper {
  padding: 20px 25px 20px 25px;
  gap: 10px;
  display: flex;
  border-radius: 25px;
  box-shadow: 3px 8px 16.6px 2px #5B50A92E;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  background: #E4E4FF66;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  box-sizing: border-box;

  .timer-card-wrapper{
    display: flex;
    padding: 30px 25px 30px 25px;
    gap: 10px;
    background: #E5E1FC;
    border-radius: 25px;
  }
  span{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--Color-8, #2E32A7);
  }
  .timer-wrap{
    padding: 4px 12px 4px 12px;
    gap: 0px;
    border-radius: 16px;
    background: var(--Color-20, #C0CAFF);
    margin-left: 20px;
    height: fit-content;
    width: fit-content;
    align-self: center;
  }
  .timer-content{
    display: flex;
    gap: 30px;
  }
  .timer-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #000000;
  }
}