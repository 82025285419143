.card_box {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e5e1fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;

  cursor: pointer;
}

.card_title {
  font-weight: 600;
}

@mixin tag {
  padding: 4px 12px 4px 12px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 16px;
  text-align: center;
}

.tag_1 {
  @include tag;
  background-color: #c0caff;
  color: #2e32a7;margin-left: 10px;

}

.tag_2 {
  @include tag;
  background-color: #e0ffcc;
  color: #487b1f;margin-left: 10px;
}

.tag_4 {
  @include tag;
  background-color: #ffd6c0;
  color: #c85616;margin-left: 10px;
}
