.card_box {
  height: 52px;
  display: grid;
  grid-template-columns: 1fr 4fr 0fr;
  align-items: center;
  background-color: #e5e1fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.card_title {
  font-weight: 600;
}

.card_container_middle {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  gap: 0 15px;
  align-items: center;
}

@mixin tag {
  padding: 4px 12px 4px 12px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 16px;
}

.tag_1 {
  @include tag;
  background-color: #c0caff;
  color: #2e32a7;
}
