.wrapper{
  display: flex;
  gap: 15px;
  align-items: end;
  width: 100%;
  &--outgoing{
    flex-direction: row;
    justify-content: right;
  }
  &--incoming{
    flex-direction: row-reverse;
    justify-content: left;
  }
  .message{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    padding: 15px;
    border-radius: 10px;
    max-width: 50%;
    word-break: break-all;
    &--outgoing{
      color: rgba(229, 225, 252, 1);
      background: rgba(91, 80, 169, 1);
    }
    &--incoming{
      background: rgba(255, 255, 255, 1);
      color: rgba(7, 5, 22, 1);
    }
  }
  .avatar_wrapper{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    svg{
      width: 32px;
      height: 32px;
    }
  }
}