.container{
  display: grid;
  grid-template-columns: 270px auto auto;
  grid-template-rows: auto auto auto auto;

  .image-wrapper{
    grid-row: 1 / span 4;
    align-self: center;
    justify-self: center;
    margin-right: 15px;
  }
  .image{
    border-radius: 20px;
  }
  .label{
    grid-column: 2;
    grid-row: 1;

    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 5px;
  }
  .blob-wrap{
    display: grid;
    grid-row: 1;
    grid-column: 4;
  }
  .blob{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    background: #5B50A9;
    width: fit-content;
    height: fit-content;
    padding: 4px 12px;
    justify-self: end;
    border-radius: 16px;
    color: #E5E1FC;
  }
  .time{
    grid-column: 2;
    grid-row: 2;

    font-size: 16px;
    font-weight: 500;
    line-height: 17px;

    margin-bottom: 10px;
  }
  .description{
    grid-column: 2 / span 3;
    grid-row: 3;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    margin-bottom: 10px;
  }
  .tags-wrapper{
    grid-column: 2;
    grid-row: 4;

    align-content: center;
  }
  .icons-wrapper{
    grid-column: 4;
    grid-row: 4;
    justify-self: end;

    > svg {
      margin-left: 5px;
    }
  }
  .tag2{
    padding: 4px 12px 4px 12px;
    border-radius: 16px;
    background: #E1DEFF;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #443896;

    margin-right: 10px;
  }
  .tag1{
    padding: 4px 12px 4px 12px;
    border-radius: 16px;
    background: #C0CAFF;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #2E32A7;

    margin-right: 10px;
  }
}
.card-wrapper{
  margin-bottom: 3vh;

  cursor: pointer;
}