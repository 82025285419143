.card-wrap{
  display: flex;
  height: fit-content;
  top: 88px;
  left: 1172px;
  padding: 13px 9px 13px 9px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid rgba(91, 80, 169, 0.15);
  box-shadow: 6px 6px 12.5px 2px #5B50A91C;
  flex-direction: column;

  cursor: pointer;

  h3{
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: left;
    color: #5B50A9;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .light-blue-div{
    padding: 4px 12px 4px 4px;
    gap: 12px;
    border-radius: 16px;
    background: var(--Second-color, #E5E1FC);
    display: flex;
    justify-content: space-between;

    span{
      display: flex;
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #5B50A9;
    }
    .span-wrap{
      padding: 2px 10px 2px 10px;
      gap: 0px;
      border-radius: 16px;
      background: #FFFFFF;
      border: 1px solid #C7BFFF;
      display: flex;
      align-self: center;
    }
  }
  .orange-div{
    padding: 4px 12px 4px 4px;
    gap: 12px;
    border-radius: 16px;
    background: var(--Color-25, #FFD6C0);
    display: flex;
    justify-content: space-between;

    span{
      display: flex;
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--Color-11, #C85616);
    }
    .span-wrap{
      padding: 2px 10px 2px 10px;
      gap: 0px;
      border-radius: 16px;
      background: #FFFFFF;
      border: 1px solid var(--Color-23, #E9CEBA);
      display: flex;
      align-self: center;
    }
  }
  .pink-div{
    padding: 4px 12px 4px 4px;
    gap: 12px;
    border-radius: 16px;
    background: var(--Color-24, #FDE4FF);
    display: flex;
    justify-content: space-between;

    span{
      display: flex;
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--Color-5, #BA1474);
    }
    .span-wrap{
      padding: 2px 10px 2px 10px;
      gap: 0px;
      border-radius: 16px;
      background: #FFFFFF;
      border: 1px solid var(--Color-6, #F4E9FA);
      display: flex;
      align-self: center;
    }
  }
  .blue-div{
    padding: 4px 12px 4px 4px;
    gap: 12px;
    border-radius: 16px;
    background: var(--Color-20, #C0CAFF);
    display: flex;
    justify-content: space-between;

    span{
      display: flex;
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: var(--Color-8, #2E32A7);
    }
    .span-wrap{
      padding: 2px 10px 2px 10px;
      gap: 0px;
      border-radius: 16px;
      background: #FFFFFF;
      border: 1px solid var(--Color-19, #BEBAE9);
      display: flex;
      align-self: center;
    }
  }
}