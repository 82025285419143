.main-wrapper{
  display: flex;
  justify-content: space-between;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.icons-wrapper {
  display: flex;
  padding: 7px 0px 0px 0px;
  flex-direction: row;
  align-items: center;
  gap: 1px;
}
.avatar-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.modal-wrapper{
  border-radius: 16px;
}
.cover{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.button {
  padding: 8px 14px ;
  border-radius: 8px;
  border: 2px solid #5B50A9;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #5B50A9;

  background: transparent;
  cursor: pointer;

  transition: .3s ease;
  .modal-text{
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    margin-bottom: 3px;
  }
  .label{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: #FFD6C0;
    color: #C85616 ;
    padding: 2px 10px;
    margin-right: 5px;
  }
  .cover-wrap{
    display: flex;
    flex-direction: column;
  }
  .vacation-wrap{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 20px;
  }
  .top-wrap{
    display: flex;
    flex-direction: row;
  }
  .tag-wrap{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 5px;
  }
  .label-wrap{
    display: flex;
  }
  .time-wrap{
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  .difficulty-wrap{
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-left: 10px;
  }
  .mid-wrap{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .material-wrap{
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  &:hover{
    color: #E5E1FC;
    background: #5B50A9;
    transition: .3s ease;
    > svg > path {
      stroke: #f6f5ff;
      transition: .3s ease;
    }
  }
}
.button-more {
  padding: 4px 7px 4px 7px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #5B50A9;
  background-color: #5B50A9;
}
.button-wrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}
.icon-wrapper{
  height: 30px;
  width: 30px;
  cursor: pointer;
  > * {
    transition: 0.3s ease !important;
  }

  >svg{
    fill: #f6f5ff;
  }

  > svg:hover{
    fill: #5B50A9;
    > path:not(:first-child) {
      stroke: #ffffff;
    }
  }
}

.hidden-wrapper{
  max-height: 0;
  transition: .6s ease-in-out;
  overflow: hidden;
}

.open {
  max-height: 70px !important;
}


.button {
  padding: 8px 14px ;
  border-radius: 8px;
  border: 2px solid #5B50A9;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #5B50A9;

  background: transparent;
  cursor: pointer;

  transition: .3s ease;

  &:hover{
    color: #E5E1FC;
    background: #5B50A9;
    transition: .3s ease;
    > svg > path {
      stroke: #f6f5ff;
      transition: .3s ease;
    }
  }
}
.button-more {
  padding: 4px 7px 4px 7px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #5B50A9;
  background-color: #5B50A9;
}
.button-wrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}
.drop-div-wrapper{
  display: flex;
  flex-direction: column;
}
.select-div{
  display: flex;
  gap: 10px;
  height: fit-content;
}
.select{
  width: 235px;
  height: 45px;
}
.search{
  width: 320px;
}
.drop-div{
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
.div-button-wrapper{
  display: flex;
  height: 45px;
  align-items: center;
}
.reverse-button {
  padding: 6px 14px 6px 14px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #5B50A9;
  background-color: #5B50A9;
  margin-left: 5px;
}
.reverse-button-text {
  font-size: 14px;
  font-weight: 590;
  line-height: 18px;
  text-align: left;
  color: #E5E1FC;
}

.icon-wrapper{
  height: 30px;
  width: 30px;
  cursor: pointer;
  > * {
    transition: 0.3s ease !important;
  }

  >svg{
    fill: #f6f5ff;
  }

  > svg:hover{
    fill: #5B50A9;
    > path:not(:first-child) {
      stroke: #ffffff;
    }
  }
}

.hidden-wrapper{
  max-height: 0;
  transition: .6s ease-in-out;
  overflow: hidden;
}

.open {
  max-height: 70px !important;
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}
