.container{
  width: 265px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.label {
  font-size: 16px;
  font-weight: 600;
}