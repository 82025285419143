.container{
  width: 251px;
  height: 34px;
  border-radius: 40px ;
  border: 2px solid #C7BFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  border: 0;
  background: transparent;
  outline: 0;
  width: 80%;
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}