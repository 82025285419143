.main_wrapper{
  padding: 9px 20px;
  border-radius: 20px;
  background: rgba(229, 225, 252, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .text{
    display: flex;
    flex-direction: column;
    .name{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin: 0;
    }
    .description{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
      color: rgba(22, 16, 64, 0.3);
      margin: 0;
    }
  }
}