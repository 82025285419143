.card-wrapper {
  padding: 20px 25px 20px 25px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 3px 8px 16.6px 2px #5B50A92E;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  background: #E4E4FF66;
  width: fit-content;
  height: fit-content;

  .graphic-card-wrap {
    display: flex;
    flex-direction: row;
  }
  .graphic-card {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  h3{
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }
  span{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
  }

}