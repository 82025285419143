@import "./normalize.scss";

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #ebe8ff;
}

::-webkit-scrollbar-thumb {
  background: #5b50a966;
  border-radius: 10px;
}
