.PageWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 2vw;
  width: 100%;

  .content_wrapper {
    display: grid;
    gap: 25px;
    grid-template-columns: 58% 40%;

    .header {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 25px;
      grid-column: 2;
      cursor: pointer;
      .card_wrapper {
        display: grid;
        gap: 32px;
        grid-template-columns: auto 123px;

        .column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 10px;
          &:nth-child(1){
            grid-column: 1;
          }
          &:nth-child(2){
            grid-column: 2;
          }
          .date {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #5b50a9;
          }
          .main {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .paragraph {
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              margin: 0;
            }
          }

          .gray_text {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #1610404d;
          }

          .trash_wrapper {
            margin-left: auto;
          }
        }
      }

      .calendar_header {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #1610404d;
      }

      .form_card {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .form {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .input_wrapper {
            display: flex;
            gap: 10px;
          }

          .second_line {
            display: flex;

            .checkbox-wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-right: 62px;

              .checkbox {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #070516;

                > span {
                  text-wrap: nowrap;
                }
              }
            }

            .placeholder {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: #1610404d;
            }
          }
        }

        .third_line {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 44px;

          .select {
            width: 100%;
          }
        }
      }

      .settings_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 18px;
      }
    }
  }
}


.cards-wrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
  grid-column: 1;
}

.calendar-image{
  cursor: pointer;
  >div {
    width: 100%;
    border: 1px solid rgba(91, 80, 169, 0.15);
    border-radius: 20px;
    margin-top: 10px;
    box-shadow: 3px 8px 16.6px 2px rgba(91, 80, 169, 0.1803921569);
    overflow: hidden;
  }
}