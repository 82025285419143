.page-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 3vw;

  margin-left: 2vw;
  margin-bottom: 2vh;

  * {
    box-sizing: border-box;
  }
}

.card-wrapper{
  display: flex;
  gap: 20px;
  flex-direction: column;
}
