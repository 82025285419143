.employee-wrapper {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  * {
    box-sizing: border-box;
    // border: 1px solid black;
  }

  .employee {
    box-sizing: border-box;
    max-width: 525px;
    flex-shrink: 0;
    background-color: #5b50a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 83px;

    .employee-name {
      text-align: center;

      h3 {
        color: #e5e1fc;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        margin-top: 10px;
      }

      span {
        margin-top: 5px;
        color: #bab1f94d;
      }
    }

    .employee-position-list {
      margin-top: 25px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 25px;
    }

    button {
      margin-top: 15px;
      width: 174px;
      height: 36px;
      border-radius: 8px;
      background-color: #e5e1fc;
      color: #5b50a9;
      font-size: 14px;
      border: 0px;
      cursor: pointer;
    }
  }
}

.employee-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 525px);
  padding: 0 25px 25px 25px;
  flex-shrink: 1;

  .employee-aim-list {
    display: grid;
    grid-template-columns: auto auto;
    gap: 25px;
    margin-top: 31px;
    width: 100%;
  }

  .employee-status {
    width: 100%;
    margin-top: 25px;
    height: 66px;
    background-color: rgba(228, 228, 255, 0.4);
    border: 1px solid;
    border-image-source: linear-gradient(
                    90deg,
                    rgba(91, 80, 169, 0.15) 0%,
                    rgba(252, 251, 255, 0.5) 100%
    );
    box-shadow: 3px 8px 16.6px 2px #5b50a940;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .employee-status-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;

      h3 {
        font-size: 16px;
        font-weight: 600;
      }

      button {
        background-color: #e0ffcc;
        width: 86px;
        height: 26px;
        border-radius: 16px;
        color: #487b1f;
        font-size: 16px;
        font-weight: 500;
        border: 0;
      }
    }
  }

  .employee-analitics {
    width: 100%;
    margin-top: 25px;
    background-color: rgba(228, 228, 255, 0.4);
    border: 1px solid;
    border-image-source: linear-gradient(
                    90deg,
                    rgba(91, 80, 169, 0.15) 0%,
                    rgba(252, 251, 255, 0.5) 100%
    );
    box-shadow: 3px 8px 16.6px 2px #5b50a940;
    padding: 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      width: 70%;
    }
  }

  .employee-vacation {
    width: 100%;
    margin-top: 22px;
    background-color: rgba(228, 228, 255, 0.4);
    border: 1px solid;
    border-image-source: linear-gradient(
                    90deg,
                    rgba(91, 80, 169, 0.15) 0%,
                    rgba(252, 251, 255, 0.5) 100%
    );
    box-shadow: 3px 8px 16.6px 2px #5b50a940;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 34px;
    border-radius: 20px;

    h3{
      font-size: 16px;
    }

    button{
      width: 100px;
      height: 40px;
      background-color: #5B50A9;
      color: #E5E1FC;
      font-size: 14px;
      border: 0;
      font-weight: 600;
      border-radius: 8px;
    }
  }
}