.main_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25.5px 18.5px;
  background: rgba(229, 225, 252, 1);
  border-radius: 20px;
  box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
  .name{
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.32px;
    text-align: center;
  }
  .description{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
  }
  .icons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    .tag{
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
      color: rgba(46, 50, 167, 1);
      background: rgba(192, 202, 255, 1);
      padding: 4px 12px 4px 12px;
      border-radius: 16px;
    }
  }
}