.emplyee-position {
  box-sizing: border-box;
  width: 203px;
  height: 88px;
  background-color: #e5e1fc;
  border-radius: 25px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  h4 {
    margin: 0;
    color: #5b50a9;
    font-size: 16px;
    font-weight: 600;
  }

  span {
    color: #5b50a9;
    font-size: 12px;
    font-weight: 400;
  }
}
