.card_box {
  display: flex;
}

.conainer_right {
  margin-bottom: 2em;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #5b50a926 0%, #fcfbff80 100%);
  background: #e4e4ff66;
  box-shadow: 8px 12px 20px 2px #5b50a940;
  display: flex;
  flex-direction: column;
  padding: 27px 25px 27px 32px;
}
