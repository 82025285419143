.card {
  box-sizing: border-box;
  padding: 15px 11px;
  padding-right: 22px;
  height: 78px;
  border-radius: 20px;
  background-color: #e5e1fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  .employee-wrapper {
    display: flex;
    gap: 16px;

    .employee-info {
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 18px;
        color: #070516;
        margin: 0;
      }

      span {
        color: #1610404d;
      }
    }
  }

  button {
    padding: 0 10px;
    height: 26px;
    border-radius: 16px;
    border: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #C85616;
    background-color: #FFD6C0;
    cursor: pointer;
  }

  button.active {
    color: #2e32a7;
    background-color: #c0caff;
  }
}
