.scroll_container{
  overflow: hidden;
  position: relative;
  padding: 40px;
  border-radius: 40px;
  background: #E4E4FF66;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 8px 12px 20px 2px #5B50A940;
  height: 82vh;
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  &:active{
    cursor: grabbing;
  }
  .main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
    position: absolute;
  .card_row{
    display: flex;
    flex-direction: row;
    gap: 40px;
  }
  .lines{
    padding-left: 150px;
    padding-top: 10px;
  }
}}