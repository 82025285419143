.card-wrapper {
  padding: 20px 25px 20px 25px;
  gap: 10px;
  display: flex;
  border-radius: 25px;
  box-shadow: 3px 8px 16.6px 2px #5B50A92E;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  background: #E4E4FF66;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;

  .graphic-cards-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
  h3{
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
    text-align: left;
    margin-top: 0px;
  }
  span{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
  }
  .graphic-card{
    display: flex;
    flex-direction: row;
  }
  .text-wrap{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }
}