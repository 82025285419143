.main_wrapper{
  background: #E4E4FF66;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 8px 12px 20px 2px #5B50A940;
  padding: 18px 15px 28px 12px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .left_part{
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    .image_wrapper{
      flex-shrink: 0;
    }
    .titles{
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      justify-content: left;
      .title_upper{
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
        .title{
          width: 100%;
          font-size: 20px;
          font-weight: 600;
          line-height: 27.32px;
          text-align: left;
        }
        .name{
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 21.86px;
          text-align: left;
        }
      }
      .title_lower{
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: center;
      .info{
        display: flex;
        flex-direction: column;
        justify-content: left;
        .info_text{
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;

        }
      }
      .card_text{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        height: 100%;
        width: 50%;
      }
    }
    }
  }
  .percent{
    font-size: 40px;
    font-weight: 700;
    line-height: 54.64px;
    text-align: left;
    color: #C7BFFF;
  }
  .progress_bar{
    position: absolute;
    border-radius: 0 10px 10px 20px;
    height: 10px;
    width: 66%;
    left: 0;
    bottom: -1px;
    background: #5B50A9;
  }
}