.main_menu_wrapper_collapsed{
  position: fixed;
  left: 0;
  top: 0;
  width: fit-content;
  height: 100vh;
  background-color: #5B50A9;
  display: flex;
  flex-direction: column;
  padding: 3vh 1vw 1.5vh 1vw;
  overflow: hidden;
  max-width: 80px;
  transition: .6s ease;
  box-sizing: border-box;
  .logo_wrapper{
    height: 60px;
    min-height: 60px;
    margin-bottom: 20px;
    width: 45px;
    max-width: 45px;
    position: relative;

    .logo-no-text{
      position: absolute;
      top: 0;
      left: 0;
    }

    .text-logo {
      top: 1px;
      position: absolute;
      left: -9px;
    }
  }
  .menu_items{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 45px;
    gap: 4px;
    transition: 0.6s ease;
    max-width: 45px;
    .menu_item{
      width: 45px;
      display: flex;
      justify-content: left;
      min-height: 48px;
      flex-direction: column;
      max-height: 48px;
      transition: .6s ease;


      .subMenu {
        z-index: 1;
        display: flex;
        margin-top: -36px;
        padding-top: 46px;
        flex-direction: row;
        gap: 20px;
        height: fit-content;
        padding-left: 20px;
        background-color: #857BCF;
        border-radius: 20px;
        padding-bottom: 8px;
        max-height: 0;
        overflow: hidden;
        transition: 0.6s ease;

        .actual_bar{
          height: 128px;
          border: 2px solid #EBE8FFB5;
          border-radius: 10px;
          position: relative;
          .actual_bar_thumb{
            height: 24px;
            width: 100%;
            position: absolute;
            border: 2px solid #9E93E9;
            border-radius: 10px;
            transition: 0.3s ease;
            top: -3px;
            left: -2px;
            &_recruitmentVacancies{
              top: -3px;
              left: -2px;
            }
            &_recruitmentInterview{
              top: 40px;
              left: -2px;
            }
            &_recruitmentPersonnelReserve{
              top: 80px;
              left: -2px;
            }
            &_recruitmentTestTasks{
              top: 125px;
              left: -2px;
            }
          }
        }
        .subMenu_options{
          cursor: pointer;
          color: #E5E1FC;
          font-size: 16px;
          font-weight: 500;
          line-height: 21.86px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }


      .item_content{
        cursor: pointer;
        text-wrap: nowrap;
        width: 45px;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 25px;
        background: transparent;
        height: 45px;
        box-sizing: border-box;
        padding: 8px;
        color: #C7BFFF;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        max-width: 45px;
        min-width: 45px;
        z-index: 2;
        transition: background 0.3s ease, color 0.6s ease, box-shadow 0.6s ease, width 0.6s ease, border-radius 0.6s ease, display 0.6s ease, flex-direction 0.6s ease, justify-content 0.6s ease, align-items 0.6s ease, gap 0.6s ease, height 0.6s ease, box-sizing 0.6s ease, padding 0.6s ease, font-size 0.6s ease, font-weight 0.6s ease, line-height 0.6s ease, max-width 0.6s ease, min-width 0.6s ease;

        &--selected{
          background: #E5E1FC;
          color: #5B50A9;
          box-shadow: 6px 6px 12.5px 2px #1A115730;
          transition: background 0.3s ease, color 0.6s ease, box-shadow 0.6s ease, width 0.6s ease, border-radius 0.6s ease, display 0.6s ease, flex-direction 0.6s ease, justify-content 0.6s ease, align-items 0.6s ease, gap 0.6s ease, height 0.6s ease, box-sizing 0.6s ease, padding 0.6s ease, font-size 0.6s ease, font-weight 0.6s ease, line-height 0.6s ease, max-width 0.6s ease, min-width 0.6s ease;

          svg{
            path{
              stroke: #5B50A9;
            }
          }
        }
        &:not(&--selected):hover{
          background: #857BCF8C;
          transition: .3s ease-in-out;
        }
      }
    }
  }
  .arrow_button_right{
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
    transition: 0.6s ease;
    margin-top: auto;
    svg{
      transition: 0.6s ease;
      margin-left: 12px;
    }
  }
}

.main_menu_wrapper{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #5B50A9;
  display: flex;
  flex-direction: column;
  padding: 3vh 1vw 1.5vh 1vw;
  overflow: hidden;
  max-width: 285px;
  transition: .6s ease;
  box-sizing: border-box;
  .logo_wrapper{
    height: 60px;
    min-height: 60px;
    margin-bottom: 20px;
    position: relative;
    max-width: 250px;

    .logo-no-text{
      position: absolute;
      left: 99px;
      top: 0;
    }

    .text-logo {
      top: 1px;
      position: absolute;
      left: 90px;
    }
  }
  .menu_items{
    max-height: 92vh;
    text-wrap: nowrap;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 250px;
    transition: 0.6s ease;
    .menu_item{
      width: 250px;
      display: flex;
      justify-content: left;
      height: fit-content;
      min-height: 48px;
      flex-direction: column;
      max-height: 250px;
      .item_content{
        cursor: pointer;
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;
        padding: 8px;
        color: #C7BFFF;
        border-radius: 40px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        background: transparent;
        max-width: 250px;
        box-sizing: border-box;
        height: 45px;
        width: 250px;
        min-width: 250px;
        transition: .3s ease;

        &--selected{
          background: #E5E1FC;
          box-shadow: 6px 6px 12.5px 2px #1A115730;
          color: #5B50A9;
          transition: .6s ease;

          svg{
            path{
              stroke: #5B50A9;
            }
          }
        }
        &:not(&--selected):hover{
          background: #857BCF8C;
        }
      }
      .subMenu {
        z-index: 1;
        display: flex;
        margin-top: -36px;
        padding-top: 46px;
        flex-direction: row;
        gap: 20px;
        height: fit-content;
        padding-left: 20px;
        background-color: #857BCF;
        border-radius: 20px;
        padding-bottom: 8px;
        max-height: 0;
        overflow: hidden;
        transition: 0.6s ease;

        .actual_bar{
          height: 128px;
          border: 2px solid #EBE8FFB5;
          border-radius: 10px;
          position: relative;
          .actual_bar_thumb{
            height: 24px;
            width: 100%;
            position: absolute;
            border: 2px solid #9E93E9;
            border-radius: 10px;
            transition: 0.3s ease;
            top: -3px;
            left: -2px;
            &_recruitmentVacancies{
              top: -3px;
              left: -2px;
            }
            &_recruitmentInterview{
              top: 40px;
              left: -2px;
            }
            &_recruitmentPersonnelReserve{
              top: 80px;
              left: -2px;
            }
            &_recruitmentTestTasks{
              top: 125px;
              left: -2px;
            }
          }
        }
        .subMenu_options{
          cursor: pointer;
          color: #E5E1FC;
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

    }
  }
  .arrow_button_left{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    transition: 0.6s ease;
    width: 250px;
    cursor: pointer;
    margin-top: auto;
    svg{
      transition: 0.6s ease;
      transform: rotate(180deg);
      margin-left: 230px;
    }
  }
}

.show {
  max-height: 175px !important;
  transition: .3s ease-in-out;
}


.hide {
  background-color:transparent !important;
  transition: .3s ease;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.position-0 {
  top: -3px !important;
}

.position-1 {
  top: 32px !important;
}

.position-2 {
  top: 68px !important;
}

.position-3 {
  top: 103px !important;
}


.elements-3 {
  height: 94px !important;
}
.elements-2 {
  height: 58px !important;
}