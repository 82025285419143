.page-wrapper{
  display: flex;
  width: 100%;
  margin-right: 2vw;
  margin-left: 2vw;
  flex-direction: column;
}
.card-wrap{
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}
.cards-wrap{
   display: flex;
   flex-direction: column;
  width: 100%;
 }
.graphics-wrap{
  display: flex;
  width: 440px;
  min-width: 440px;
}
.clicable-wrapper{
  cursor: pointer;
}