@import '../../shared/constants/colors';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: $BACKGROUND_COLOR;
  font-family: 'Manrope', sans-serif;
}

body > #root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin: 0;
}