.title{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  margin-bottom: 10px;
}
.description{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.third-blob{
  grid-column: 2;
  grid-row: 2;
}
.text-wrapper{
  display: flex;
  justify-content: space-around;
}
.centered{
  display: flex;
  flex-direction: column;
  place-items: center;
}
.hh-wrapper{
  position: absolute;
  right: 4%;
  top: 8%;
}