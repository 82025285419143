.main_wrapper{
  box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  background: rgba(228, 228, 255, 0.4);
  border-radius: 20px;
  overflow-y: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px 20px 0 20px;
  width: 100%;
  height: 91.5vh;
  .messages{
    overflow-y: scroll;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 40px;
    flex-grow: 1;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .input_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .send{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input{
    border-radius: 0 0 20px 20px;
    position: absolute;
    bottom: 0;
  }
}