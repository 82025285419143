.main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  .content{
    display: flex;
    flex-direction: row;
    gap: 25px;
    .buttons{
      display: flex;
      flex-direction: column;
      gap: 25px;
      .part_button{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 40px;
        border-radius: 20px;
        border: 1px solid;
        border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
        background: #5B50A9;
        box-shadow: 8px 12px 20px 2px #5B50A940;
        color: #E5E1FC;
        .icons{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
          svg{
            path{
              stroke: #E5E1FC;
            }
          }
        }
      }
      .add_button{
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 10px 16px 10px 16px;
        border-radius: 8px;
        align-items: center;
        background: #5B50A9;
        color: #E5E1FC;
        box-shadow: 0 1px 2px 0 #1018280D;
        width: fit-content;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .task_parts{
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }
  .add_block_button{
    display: flex;
    width: fit-content;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding: 10px 16px 10px 16px;
    background: #5B50A9;
    color: #E5E1FC;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    margin-left: auto;
    margin-top: 5px;
  }
}