.container{
  width: 265px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.checkbox-wrapper{
  width: fit-content;
  gap: 0 !important;
  text-wrap: nowrap;
  > span {
    color: rgba(22, 16, 64, 0.3);
    margin-top: 2px;
    font-size: 14px;
    line-height: 14px;
    margin-left: 24px;
  }
}

.label {
  font-size: 16px;
  font-weight: 600;
}