.main_wrapper{
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: center;
  .job_period{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    background: #C7BFFF;
    border-radius: 18px;
    padding: 10px;
    height: fit-content;
  }
  .job_company{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
  .job_position{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    line-height:29px;
  }
}