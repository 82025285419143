.page-wrapper{
  display: flex;
  width: 100%;
  margin-right: 2vw;
  margin-left: 2vw;
  flex-direction: column;
}
.content-wrap{
  display: flex;
}
.table-wrap{
  display: flex;
  margin-top: 7px;
}