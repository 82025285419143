.blobs-wrapper{
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
}
.vacation-page{
  width: 100%;
  min-height: 98vh;
  margin-right: 2vw;

  margin-left: 2vw;
}
.right-row{
  display: flex;
  flex-direction: column;
  gap: 3vh;
  margin-left: 3vh;
}
.title{
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;

  margin: 25px 0 20px 0;
}

.cards-wrapper{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 25px;
}