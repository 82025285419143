$PRIMARY_COLOR: #925ee1;
$SECONDARY_COLOR: #e7e7f4;
$TERTIARY_COLOR: #bc9fea;
$ACCENT_COLOR: #6021cd;
$BACKGROUND_COLOR: #f6f5ff;
$NEUTRAL_COLOR_FIRST: #b1b7c1;
$NEUTRAL_COLOR_SECONDARY: #e0e2e5;
$NEUTRAL_COLOR_THIRD: #7f7f7f;
$ERROR_COLOR: #dd331d;
$SUCCESS_COLOR: #68952d;
$INFO_COLOR: #1677ff;
$BLACK_COLOR: #000000;
$WHITE_COLOR: #ffffff;
