.card{
  padding: 25px 30px 37px 30px;
  border-radius: 40px;
  background: #E4E4FF66;
  box-shadow: 8px 12px 20px 2px #5B50A940;
  border: 1px solid rgba(91, 80, 169, 0.15);;
  width: fit-content;
  height: fit-content;

  position: relative;
}

.light{
  box-shadow: 3px 8px 16.6px 2px #5B50A92E;
}

.full-width{
  width: calc(100% - 60px);
}

.full-height{
  height:  calc(100% - 62px);
}

.squared{
  padding: 15px 30px 15px 30px;
  border-radius: 20px;
}

.balanced{
  padding: 20px 30px;
  border-radius: 20px;
}

.twenty{
  padding: 24px 22px;
  border-radius: 40px;
}