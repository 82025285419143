.content-wrap{
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.wrapper{
  position: relative;
  width: 936px;
  height: 696px;
}
.image-wrapper{
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  position: absolute;
}
.icon-wrapper{
  display: flex;
  margin-top: 10px;
  margin-left: 16px;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: #5B50A9;
  box-shadow: 8px 12px 20px 2px #5B50A940;
  border-radius: 20px;
  width: 41px;
  height: 41px;
}
.siri-wrapper{
  display: flex;
  position: absolute;
  z-index: 1;
  height: fit-content;
  justify-content: center;
  bottom: 0px;
}
.settings-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.options{
  display: flex;
  width: fit-content;
}
.call-wrap{
  display: flex;
  flex-direction: row;
  gap: 30px;
  scale: 0.8;
}
.chat-div-wrap{
  display: flex;
  flex-direction: column;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  width: 334px;
  max-height: 696px;
}