.main_wrapper{
  border-radius: 20px;
  box-shadow: 8px 12px 20px 2px #5B50A940;
  display: flex;
  padding: 30px 16px 30px 25px;
  gap: 10px;
  background: #E4E4FF66;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);

  cursor: pointer;
}

.card_pfp{
  flex-shrink: 0;

  display: flex;
  align-items: center;
}

.card_info_wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-wrap: nowrap;
  .card_info_title{
    margin:0;
    font-size: 16px;
  }
  .card_info_content{
    margin: 0;
    font-size: 12px;
  }
}

.card_content_wrapper{
  font-weight: 500;
  max-width: 78%;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  .card_status{
    width: fit-content;
    border-radius: 16px;
    padding: 4px 12px 4px 12px;
    font-size: 16px;
    margin-bottom: 20px;
    &--inSearch{
      background: #E0FFCC;
      color: #487B1F;
    }
    &--notInSearch{
      color: #C85616;
      background: #FFD6C0;
    }
  }
  .card_content{
    margin: 0;
    font-size: 14px;
  }
}
.card_icons{
  display: flex;
  gap: 5px;
}

.align {
  align-self: end;
  background: rgb(225, 222, 255) !important;
  color: rgb(68, 56, 150) !important;
}