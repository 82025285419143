.main-wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.avatar-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.tab_button{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding: 8px 14px 8px 14px;
  border: 2px solid #5B50A9;
  box-shadow: 0 1px 2px 0 #1018280D;
  cursor: pointer;
  border-radius: 8px;
  &_active{
    background: #5B50A9;
    color: #E5E1FC;
  }
  &_inactive{
    background: #F6F5FF;
    color: #5B50A9;
  }
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}