.container{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;

  .label{
    grid-column: 1;
    grid-row: 1;

    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  }
  .blob{
    grid-column: 2;
    grid-row: 1;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: rgba(229, 225, 252, 1);

    padding: 4px 10px 4px 12px;
    gap: 4px;
    border-radius: 16px;
    background: rgba(91, 80, 169, 1);

    width: fit-content;
    justify-self: end;
  }
  .salary{
    grid-column: 1;
    grid-row: 2;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    margin-bottom: 10px;
  }
  .description{
    grid-column: 1 / span 2;
    grid-row: 3;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    margin-bottom: 10px;
  }
  .tags-wrapper{
    grid-column: 1;
    grid-row: 4;

    align-content: center;
  }
  .icons-wrapper{
    grid-column: 2;
    grid-row: 4;
    justify-self: end;

    > svg {
      margin-left: 5px;
    }
  }
  .tag{
    padding: 4px 12px 4px 12px;
    border-radius: 16px;
    background: #E1DEFF;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #443896;

    margin-right: 10px;
  }
}

.card-wrapper{
  margin-bottom: 3vh;

  cursor: pointer;
}