.first-blob{
  grid-row: 1;
  grid-column: 1;

  .first-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .gray-text{
      grid-column: 1;

      color: rgba(22, 16, 64, 0.3);

      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      height: fit-content;
    }
    .icons-wrapper{
      grid-column: 2;
      justify-self: end;

      height: 25px;
      display: flex;
      gap: 5px;
    }
  }

  .second-row{
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .title{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;

      margin-right: 5px;
    }
    .blob{
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
      color: rgba(229, 225, 252, 1);

      padding: 4px 10px 4px 12px;
      gap: 4px;
      border-radius: 16px;
      background: rgba(91, 80, 169, 1);

      margin-right: 5px;
    }
    .small-text{
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      margin-left: 5px;
    }
  }
  .third-row{
    margin-bottom: 15px;
    .normal-text{
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }
  .fourth-row{
    display: grid;
    grid-template-columns: 60% 15% 15%;
    gap: 25px;
    margin-bottom: 25px;
    .title{
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;

      margin-bottom: 5px;
    }
    .normal-text{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
    .gray-text{
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: rgba(22, 16, 64, 0.3);
      margin-left: 5px;
    }
    .first-column{
      grid-column: 1;
    }
    .second-column{
      grid-column: 2;
      display: flex;
      flex-direction: column;
    }
    .third-column{
      grid-column: 3;
      display: flex;
      flex-direction: column;
    }
    .text-wrapper{
      margin-bottom: 5px;
    }
  }
  .fifth-row{
    display: flex;
    gap: 10px;
    align-items: center;

    .label{
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;

      color: rgba(32, 26, 68, 1);
    }
    .blobbed-text{
      font-size: 16px;
      font-weight: 500;
      background: #C7BFFF;
      padding: 6px 19px 7px 18px;
      border-radius: 18px;

      margin-right: 5px;
    }
    .blobs-wrapper{
      margin-top: 8px;
    }
    .third-wrapper{
      margin-left: auto;
    }
    .title{
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}