.page-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 3vw;

  margin-left: 1vw;
}
.forms-wrapper{
  display: flex;
}

.right-wrapper{
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
  gap: 2vh;
}