.emplyee-aim{
  width: 100%;
  height: 171px;
  background-color: #E4E4FF66;
  border-radius: 25px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 3px 8px 16.6px 2px #5B50A940;
  padding: 20px 11px 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .aim-title{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
      margin: 0;
      
      font-size: 16px;
      font-weight: 600;
    }
  }

  .aim-info{

    .aim-time{
      font-size: 16px;
      font-weight: 600;
    }

    .aim-stroke-list{
      margin-top: 10px;
      display: flex;
      gap: 2px;
    }

    .aim-peoples-wrapper{
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }


}