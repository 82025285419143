.main_wrapper{
  border: 1px solid;
  border-radius: 40px;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 8px 12px 20px 2px #5B50A940;
  background: #F6F6FF;
  padding: 15px 25px 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  justify-content: left;

  .card_title{
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    text-align: left;
  }
  .content{
    display: flex;
    flex-direction: row;
    gap: 15px;
    .avatar_wrapper{
      flex-shrink: 0;
    }
    .text_wrapper{

      .name{
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        margin: 0;
      }
      .email{
        color: #1610404D;
        opacity: 70%;
        margin: 0;
      }
    }
  }
}