.main_wrapper{
  display: flex;
  flex-direction: column;
  background: rgba(91, 80, 169, 1);
  width: 31vw;
  align-items: center;
  padding-top: 9.2vh;
  flex-shrink: 0;
  .avatar_wrapper{
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    box-shadow: 3px 4px 10.8px 0 rgba(51, 44, 107, 1);
    border-radius: 100%;
    svg{
      width: 150px;
      height: 150px;
      flex-shrink: 0;
    }
  }
  .name{
    margin: 10px 0 0 0;
    color: rgba(229, 225, 252, 1);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
  .email{
    margin: 5px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(186, 177, 249, 0.87);
    text-decoration: underline;
  }
  .button{
    color: rgba(91, 80, 169, 1);
    background: rgba(229, 225, 252, 1);
    border: 1px solid rgba(229, 225, 252, 1);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    padding: 8px 14px 8px 14px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-top: 15px;
  }
  .positions{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    gap: 20px;
    margin: 25px 0 10px 0;
    .position{
      color: rgba(91, 80, 169, 1);
      background: rgba(229, 225, 252, 1);
      padding: 20px 65px 20px 25px;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 90px;
      height: 62px;
      .name_pos{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 21.86px;
        text-align: left;
      }
      .time{
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}