.main_wrapper{
  border-radius: 40px;
  border: 1px solid rgba(91, 80, 169, 0.15);
  background: #E4E4FF66;
  box-shadow: 8px 12px 20px 2px #5B50A940;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 27px 25px 27px 32px;
  .candidate_info_wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
    .candidate_info_image_wrapper{
      flex-shrink: 0;
    }
    .candidate_info_text{
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      .candidate_info_upper{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .candidate_info_title{
          display: flex;
          flex-direction: row;
          gap: 7px;
          .candidate_name{
            margin: 0;
            font-size: 16px;
            font-weight: 600;
          }
          .candidate_info_status{
            background: #E0FFCC;
            color: #487B1F;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            padding: 4px 12px 4px 12px;
            border-radius: 16px;
          }
        }
        .candidate_info_icons{
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
      .candidate_personal_data{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
      .candidate_about_me{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        margin: 5px 0 10px 0;
      }
      .candidate_position{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }
      .candidate_professional_data{
        display: flex;
        flex-direction: row;
        gap: 50px;
        .candidate_professional_data_text{
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  .candidate_experience{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .candidate_experience_title{
      display: flex;
      gap: 10px;
      .candidate_experience_title_text{
        font-size: 20px;
        font-weight: 600;
        line-height: 27.32px;
        margin: 0;
      }
      .candidate_experience_title_time{
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 27.32px;
        color: #1610404D;
        opacity: 70%;
      }
    }
  }
  .candidate_skills{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .candidate_skills_title{
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
    .candidate_skills_list{
      display: flex;
      flex-direction: row;
      gap: 10px;
      .candidate_skill{
        font-size: 16px;
        font-weight: 500;
        background: #C7BFFF;
        padding: 6px 19px 7px 18px;
        border-radius: 18px;
      }
    }
  }
  .candidate_degree{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .candidate_degree_title{
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }
    .candidate_degree_description{
      display: flex;
      flex-direction: row;
      gap: 10px;
      .candidate_degree_number{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 10px;
        background: #C7BFFF;
        border-radius: 18px;
        height: fit-content;
      }
      .candidate_degree_name{
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        line-height: 35px;
      }
      .candidate_degree_direction{
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        line-height: 35px;
      }
    }
  }
  .candidate_degree2{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .candidate_degree2_title{
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }
    .candidate_degree2_colleges{
      display: flex;
      flex-direction: row;
      gap: 10px;
      .candidate_degree2_college{
        font-size: 16px;
        font-weight: 500;
        background: #C7BFFF;
        padding: 6px 19px 7px 18px;
        border-radius: 18px;
      }
    }
  }
}