.main_wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.avatar_wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.title{
  margin: 0;
}
.title_wrapper{
  display: flex;
  align-items: center;
}

