.form-container{
  min-width: 55vw;
}

.first-row{
  display: flex;
  margin-bottom: 16px;

  > div {
    width: 26%;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
  }
}

.second-row{
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top:8px;
  .input{
    width: 26%;
  }
}

.third-row{
  display: flex;
  gap:4px;
  margin-top: 4px;
  input{
    width: 26%;
  }
  > div {
    width: 26%;
  }
}

.checkbox-wrapper{
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  width: fit-content;
  text-wrap: nowrap;
  > span {
    color: rgba(22, 16, 64, 0.3);
    margin-top: 2px;
    font-size: 14px;
    line-height: 14px;
    margin-left: 24px;
  }
}

.date-picker{
  width: 35%;
  margin-right: 4px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.label {
  font-size: 16px;
  font-weight: 600;
}

.radio{
  display: flex;
  flex-direction: column;
}

.employee-wrapper{
  display: flex;
}

.small-text{
  font-size: 13px;
  color: rgb(91, 80, 169);
}