.avatar_container {
  margin-left: 2em;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.avatar {
  overflow: hidden;
  margin-left: -10px !important;
}

.plus_icon {
  border-radius: 50% !important;
  overflow: hidden;
  margin-left: -10px !important;
  border: 2px solid #fff;
  width: 28px;
  height: 28px;
  font-weight: 500;
  background-color: #5b50a9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  z-index: 1;
}
