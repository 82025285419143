.main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 30px;
  .pages_grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .image_wrapper{
      width: fit-content;
      border-radius: 20px;
      margin-bottom: 2vh;
      overflow: hidden;
      box-shadow: 8px 12px 20px 2px rgba(91, 80, 169, 0.25);
      border: 1px solid;
      border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    }
  }
}