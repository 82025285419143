.card_main_wrapper{
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  background: #E4E4FF66;
  border: 1px solid;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 8px 12px 20px 2px #5B50A940;
  height: fit-content;
  h3{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.86px;
  }
  .card_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .right_title{
      display:flex;
      flex-direction: row;
      gap: 10px;
      .wrap_button_down{
        transition: 0.4s ease;
        display: flex;
        align-items: center;
        svg{
          transition: 0.4s ease;
        }
      }
      .wrap_button_up{
        display: flex;
        align-items: center;
        transition: 0.4s ease;
        svg{
          transition: 0.4s ease;
          transform: rotate(180deg);
        }
      }
    }
  }
  .hidden_block{
    overflow:hidden;
    transition: 0.4s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #E5E1FC;
    border: 1px solid;
    border-radius: 20px;
    padding: 20px;
    border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
    &--opened{
      padding: 20px;
      transition: 0.4s ease;
      margin-top: 10px;
    }
    &--collapsed{
      transition: 0.4s ease;
      height: 0;
      margin: 0;
      padding: 0;
      border: none;
    }
    .hidden_block_title{
      transition: 0.4s ease;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .icons{
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
    .hidden_block_text{
      transition: 0.4s ease;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
  }
}