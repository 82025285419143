.card_box {
  border-radius: 40px;
  border: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(91, 80, 169, 0.15) 0%,
    rgba(252, 251, 255, 0.5) 100%
  );
  background: #e4e4ff66;
  box-shadow: 3px 8px 16.6px 2px #5b50a92e;
  display: grid;
  grid-template-columns: 1fr 5.5fr;
  grid-template-rows: 1fr;
  height: 236.29px;
  gap: 20px;
  padding: 27px 25px 27px 32px;
}

.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 21.86px;
}

.page {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
}

.text_box {
  margin-top: 1.5em;
  width: 85%;
  height: 121px;
}

.date {
  font-weight: 600;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 236.29px;
  height: 236.29px;
  border-radius: 50%;
}

.circle::before {
  content: "";
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #e4e4ff;
  z-index: 1;
}

.circle_1 {
  background: conic-gradient(
    #ccccff 0deg 80deg,
    #99ccff 80deg 190deg,
    #333399 190deg 360deg
  );
}
