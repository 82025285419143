.main_wrapper{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px 20px 25px;
  background: #E4E4FF66;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, rgba(91, 80, 169, 0.15) 0%, rgba(252, 251, 255, 0.5) 100%);
  box-shadow: 3px 8px 16.6px 2px #5B50A92E;
  border-radius: 25px;
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 16px;
      font-weight: 600;
      line-height: 21.86px;
      text-align: left;
      margin: 0;
    }
  }
  .progress_bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.1vw;
    .bar_filled{
      width: 14.2%;
      height: 4px;
      background: #5B50A9;
      border-radius: 20px;
    }
    .bar_empty{
      background: #C7BFFF;
      width: 14.2%;
      height: 4px;
      border-radius: 20px;
    }
  }
  .content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .owner_card{
      display: flex;
      flex-direction: row;
      gap: 10px;
      .avatar_wrapper{
        flex-shrink: 0;
        svg {
          width: 35px;
          height: 35px;
        }
      }
      .owner_info{
        display: flex;
        flex-direction: column;
        gap: 0;
        .name{
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }
        .email{
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: #1610404D;
          opacity: 70%;
        }
      }
    }

  }
}