.card {
  padding: 15px 11px;
  padding-right: 22px;
  height: 78px;
  border-radius: 20px;
  background-color: #e5e1fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  cursor: pointer;

  .department-wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-between;

    .department-info {
      height: inherit;
      display: flex;
      flex-direction: row;
      justify-content: center;

      h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: #070516;
        margin: 0;
        padding-left: 18.5px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        margin-top: 6px;
        margin-right: 20px;
      }
      }
  }

  button {
    padding: 0 10px;
    height: 26px;
    border-radius: 16px;
    border: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #2e32a7;
    background-color: #c0caff;
    cursor: pointer;
  }
}
