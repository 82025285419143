.main_wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  min-height: 90%;
  gap: 15px;
}

.conainer_left {
  gap: 25px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
}

.conainer_right {
  gap: 10px;
  border-radius: 40px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #5b50a926 0%, #fcfbff80 100%);
  background: #e4e4ff66;
  box-shadow: 8px 12px 20px 2px #5b50a940;
  display: flex;
  flex-direction: column;
  padding: 27px 25px 27px 32px;
}
