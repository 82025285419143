.main-wrapper{
  display: flex;
  justify-content: space-between;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.avatar-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
